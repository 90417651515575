import { useMutation, useQuery } from "@apollo/client";
import { wrapClick } from "utils";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import * as Yup from "yup";
import { GET_SERVICE_REQUEST, UPGRADE_SERVICE_REQUEST } from "./apollo";
import { LocationGenerics } from "router/location";
import {Modal, UpgradeServiceRequestForm} from "components";

export default function UpgradeServiceRequestContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [upgradeServiceRequest] = useMutation(UPGRADE_SERVICE_REQUEST, {
    refetchQueries: ["GetServiceRequest"],
  });

  const availableCategories = ["Premium","Express"].filter((category) => category !== data?.serviceRequest?.category)

  const form = useFormik<{
    category: string;
  }>({
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().oneOf(availableCategories, "Kindly choose a valid category").required("New category is required"),
    }),
    onSubmit: async (values) => {
      await upgradeServiceRequest({
        variables: {
          ...values,
          id: data?.serviceRequest?._id,
        },
      }).then(({ data }) => {
        if (data?.upgradeServiceRequest?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Request Upgraded Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not upgrade Service Request",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size='4xl'
      title='Upgrade Service Request'
      description='Provide the details to upgrade service request category'
      renderActions={() => (
        <>
          {
            Boolean(data?.serviceRequest?.category) &&
            ["Standard", "Express"].includes(data?.serviceRequest?.category) &&
            !["ConnectionFeePaid",
              "InstallationInitiated",
              "InstallationCompleted",
              "InstallationFailed",
              "Completed"].includes(data?.serviceRequest?.status) &&
            (
              <button
                type='button'
                disabled={form.isSubmitting || !form.isValid}
                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                onClick={wrapClick(form.handleSubmit)}
              >
                {form.isSubmitting ? "Upgrading ..." : "Upgrade"}
              </button>
            )
          }
        </>
      )}
    >
      {data?.serviceRequest?._id && (
        <UpgradeServiceRequestForm
          form={form}
          availableCategories={availableCategories}
          customer={{
            ...data?.serviceRequest?.request,
            status: data?.serviceRequest?.status,
            code: data?.serviceRequest?.code,
          }}
        />
      )}
    </Modal>
  );
}
