import { useQuery, useReactiveVar } from "@apollo/client";
import {
  DocumentDuplicateIcon,
  DocumentIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import config from "config";
import {
  classNames,
  useTableData,
  useUrlState,
  wrapClick,
  wrapOnchange,
} from "utils";
import lodash from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { GET_SERVICE_REQUESTS } from "./apollo";
import UpdateServiceRequestContainer from "./update";
import ViewServiceRequestContainer from "./view";
import { currentConfigVar } from "apollo/cache/config";
import { LocationGenerics } from "router/location";
import ActionButton, { Action } from "components/buttons/action-button";
import {
  OfficeCalendarHeader,
  SelectInput,
  Shimmers,
  StatusBadge,
  TableComponent,
} from "components";
import { ServiceRequestCategories } from "apollo/data";
import UpgradeServiceRequestContainer from "./upgrade";

const ServiceRequestStatuses = [
  "Pending",
  "Confirmed",
  "Rejected",
  "ApplicationFeeGenerated",
  "ApplicationFeePaid",
  "InspectionInitiated",
  "InspectionCompleted",
  "InspectionFailed",
  "ContractGenerated",
  "ContractSigned",
  "ConnectionFeeGenerated",
  "ConnectionFeePaid",
  "InstallationInitiated",
  "InstallationCompleted",
  "InstallationFailed",
  "Completed",
];

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 6,
  },
  {
    label: "Representative Name",
    value: "request.customer.representative.fullName",
    min: 4,
  },
  {
    label: "Representative Phone Number",
    value: "request.customer.representative.phoneNumber",
    min: 4,
  },
  {
    label: "Representative Ghana Card Number",
    value: "request.customer.representative.ghanaCardNumber",
    min: 4,
  },
  {
    label: "Representative Identity Card Number",
    value: "request.customer.representative.identityCardNumber",
    min: 4,
  },
];

export const ServiceRequestsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}`,
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState<string | undefined>("modal");
  const [status, setStatus] = useUrlState("status");
  const [category, setCategory] = useUrlState("category");

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const filter = useMemo(
    () => ({
      page: Number(searchParams.page || config.constants.page),
      pageSize: Number(searchParams.pageSize || config.constants.pageSize),
      search: searchParams.search || undefined,
      status: searchParams.status || undefined,
      category: searchParams.category || undefined,
      searchFields: lodash.map(searchOptions, "value"),
      sort: searchParams.sort || "",
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
    }),
    [searchParams],
  );

  const { data, loading, refetch, networkStatus } = useQuery(
    GET_SERVICE_REQUESTS,
    { variables: filter },
  );

  const records = useTableData(data ? data : []);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? {} : { search: "" }),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeCalendarHeader
        defaultView="month"
        renderActions={() => (
          <>
            <>
              <button
                type="button"
                onClick={wrapClick(() => navigate({ to: "./new" }))}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Create Service Request
              </button>
            </>
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"service requests"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
              <SelectInput
                id="status"
                label=""
                handleBlur={() => {}}
                handleChange={wrapOnchange(setStatus)}
                options={[
                  { label: "--- Select Status ---", value: "" },
                  ...ServiceRequestStatuses.map((status) => ({
                    value: status,
                    label: lodash.startCase(status),
                  })),
                ]}
                values={{ status }}
              />
              <SelectInput
                id="category"
                label=""
                handleBlur={() => {}}
                handleChange={wrapOnchange(setCategory)}
                options={[
                  { label: "--- Select Status ---", value: "" },
                  ...ServiceRequestCategories.map((status) => ({
                    value: status.value,
                    label: lodash.startCase(status.title),
                  })),
                ]}
                values={{ category }}
              />
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                {!searchParams?.region && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Region
                  </th>
                )}
                {!searchParams?.district && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    District
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  GhanaPost | Community
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Service Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                {!searchParams?.region && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {!searchParams?.district && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 flex space-x-2">
                  <div className="flex items-center justify-center text-gray-700">
                    {item?.isBulk ? (
                      <DocumentDuplicateIcon
                        data-tooltip-delay-show={0}
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content="Bulk Request"
                        data-tooltip-place="right"
                        className="h-6 w-6"
                      />
                    ) : (
                      <DocumentIcon
                        data-tooltip-delay-show={0}
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content="Single Request"
                        data-tooltip-place="right"
                        className="h-5 w-5"
                      />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.code || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </div>
                </td>
                {!searchParams?.region && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.region?.name || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.region?.code || "N/A"}
                    </div>
                  </td>
                )}
                {!searchParams?.district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.district?.name || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.district?.code || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.category === "Premium"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.category === "Standard"
                        ? `bg-gray-100 text-gray-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1",
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.category || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.request?.customer?.customerType === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.request?.customer?.customerType === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.request?.customer?.customerType
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1",
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      {item?.request?.customer?.customerType || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  {item?.request?.customer?.customerType === "Individual" ? (
                    <>
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.request?.customer?.representative?.fullName ||
                          "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.request?.customer?.representative?.phoneNumber ||
                          "N/A"}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.request?.customer?.organization?.name || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.request?.customer?.representative?.phoneNumber ||
                          "N/A"}
                      </div>
                    </>
                  )}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.request?.property?.ghanaPostAddress || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.request?.property?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.request?.service?.serviceType || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {lodash.startCase(item?.request?.service?.serviceClass) ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.status}
                    options={{
                      Pending: "bg-gray-100 text-gray-800 ",
                      Active: "bg-green-100 text-green-800 ",
                      Suspended: "bg-amber-100 text-amber-800 ",
                      Terminated: "bg-red-100 text-red-800 ",
                    }}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="upgrade"
                      disabled={
                        ["ConnectionFeePaid",
                          "InstallationInitiated",
                          "InstallationCompleted",
                          "InstallationFailed",
                          "Completed"].includes(item?.status) &&
                        ["Standard"].includes(item?.category)
                      }
                      onClick={dispatchAction(item?._id, "upgrade")}
                    />
                    {/* {withPermissions([

                      "customers:update",
                    ])( */}
                    <ActionButton
                      action="update"
                      disabled={item?.status !== "ApplicationFeeGenerated"}
                      onClick={dispatchAction(item?._id, "update")}
                    />
                    {/* )} */}
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action="expand"
                      onClick={navigateItem(item?._id)}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {Boolean(searchParams.id?.length) && (
        <>
          <ViewServiceRequestContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          {/* {withPermissions([  "customers:update"])( */}
          <UpdateServiceRequestContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : "view")}
          />
          {/* )} */}
          <UpgradeServiceRequestContainer
            open={modal === "upgrade"}
            setOpen={(val: boolean) => setModal(val ? "upgrade" : "view")}
          />
        </>
      )}
    </main>
  );
};

export default ServiceRequestsPage;
