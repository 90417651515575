import { useQuery } from "@apollo/client";
import { wrapClick } from "utils";
import { useNavigate, useSearch } from "react-location";
import { GET_SERVICE_REQUEST } from "./apollo";
import { LocationGenerics } from "router/location";
import { Modal, ServiceRequestView } from "components";
import { Action } from "components/buttons/action-button";

export default function ViewServiceRequestContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    });
  };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title="Service Request Information"
      description="Details of service request are shown below"
      size="4xl"
      renderActions={() => (
        <>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-2 sm:w-auto sm:text-sm"
            onClick={wrapClick(expandItem)}
          >
            View More
          </button>
          {data?.serviceRequest?.status === "ApplicationFeeGenerated" && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(
                dispatchAction(data?.serviceRequest?._id, "update")
              )}
            >
              Edit
            </button>
          )}
          {
            Boolean(data?.serviceRequest?.category) &&
            ["Standard", "Express"].includes(data?.serviceRequest?.category) &&
            !["ConnectionFeePaid",
            "InstallationInitiated",
            "InstallationCompleted",
            "InstallationFailed",
            "Completed"].includes(data?.serviceRequest?.status) &&
            (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(
                  dispatchAction(data?.serviceRequest?._id, "upgrade")
                )}
              >
                Upgrade
              </button>
            )

          }
        </>
      )}
    >
      {data?.serviceRequest?._id && (
        <ServiceRequestView serviceRequest={data?.serviceRequest}/>
      )}
    </Modal>
  );
}
